@import "./assets/globals.scss";
.dot {
  display: inline-block;
  animation-name: bouncing;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  &:nth-child(2) {
    animation-delay: 125ms;
  }
  &:nth-child(3) {
    animation-delay: 250ms;
  }
}

@keyframes bouncing {
  0% {
    transform: none;
  }

  33% {
    transform: translateY(-.5em);
  }

  66% {
    transform: none;
  }
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
}
.lds-grid div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 18, 184, 1.0);
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 20px;
  left: 20px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 20px;
  left: 80px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 20px;
  left: 140px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 80px;
  left: 20px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 80px;
  left: 80px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 80px;
  left: 140px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 140px;
  left: 20px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 140px;
  left: 80px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 140px;
  left: 140px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.lds-grid-small {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid-small div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(0, 18, 184, 1.0);
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid-small div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid-small div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid-small div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid-small div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid-small div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid-small div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid-small div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid-small div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid-small div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid-small {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}



